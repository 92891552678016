import React, { useEffect, useState } from 'react';
import {
  useToast,
  Box,
  Text,
  HStack,
  VStack,
  Switch,
  Image,
} from '@chakra-ui/react';

import { RiDeleteBin6Line } from 'react-icons/ri';

const MediaViewer = ({ staticPage, loading, updateFile }) => {
  const toast = useToast();
  const [images, setImages] = useState(staticPage?.content?.images);

  useEffect(() => {
    setImages(staticPage?.content?.images);
  }, [staticPage?.content?.images]);

  const handleAutoPlay = (index) => {
    setImages((prevImages) =>
      prevImages.map((image, i) =>
        i === index ? { ...image, autoPlay: !image.autoPlay } : image,
      ),
    );

    const formattedImages = images.map((image) => {
      if (image?.imageUrl?.includes('mp4')) {
        return {
          ...image,
          autoPlay: !image.autoPlay,
        };
      }
      return {
        ...image,
      };
    });

    updateFile({
      variables: {
        record: {
          content: {
            images: formattedImages,
          },
        },
        _id: staticPage?._id,
      },
    });
  };

  const deleteFile = async (index) => {
    const updatedImages = images.filter((i) => i !== index);
    setImages(updatedImages);

    const { data: response } = await updateFile({
      variables: {
        record: {
          content: {
            images: updatedImages,
          },
        },
        _id: staticPage?._id,
      },
    });
    // if (response) {
    //   toast({
    //     title: 'Image deleted',
    //     status: 'success',
    //     duration: 2000,
    //     isClosable: true,
    //   });
    // }
  };

  if (loading || images === undefined || images.length === 0) {
    return null;
  }

  return (
    <Box overflowX="auto" maxWidth="100%">
      <HStack gap={5} py={10} flexWrap={'wrap'} alignItems="flex-end">
        {images.map((image, index) => {
          if (image?.imageUrl?.includes('mp4')) {
            const isAutoPlay =
              image?.autoPlay === undefined ? true : image?.autoPlay;

            return (
              <VStack
                key={image._id}
                backgroundColor={'#f5f5f5'}
                p={2}
                shadow={'md'}
                borderWidth={1}
              >
                <HStack
                  cursor={'pointer'}
                  justifyContent="space-between"
                  w="100%"
                >
                  <HStack>
                    <Text fontSize={12}>Auto play : </Text>

                    <Switch
                      size="sm"
                      isChecked={isAutoPlay}
                      onChange={() => {
                        handleAutoPlay(index);
                      }}
                    />
                  </HStack>
                  <Box
                    onClick={() => deleteFile(image, index)}
                    cursor={'pointer'}
                    alignSelf={'center'}
                    borderRadius={'full'}
                  >
                    <RiDeleteBin6Line color="grey" size={20} />
                  </Box>
                </HStack>
                <Box>
                  <iframe
                    title="post-video"
                    src={image?.imageUrl}
                    height={90}
                    width={150}
                  />
                </Box>
              </VStack>
            );
          }
          return (
            <VStack
              key={image._id}
              backgroundColor={'#f5f5f5'}
              p={2}
              shadow={'md'}
              borderWidth={1}
            >
              <HStack
                cursor={'pointer'}
                justifyContent="space-between"
                w="100%"
              >
                <HStack>
                  <Text fontSize={12}>{'  '}</Text>
                </HStack>
                <Box
                  onClick={() => deleteFile(image, index)}
                  cursor={'pointer'}
                  alignSelf={'center'}
                  borderRadius={'full'}
                >
                  <RiDeleteBin6Line color="grey" size={20} />
                </Box>
              </HStack>
              <Box>
                <Image src={image?.imageUrl} height={90} width={150} />
              </Box>
            </VStack>
          );
        })}
      </HStack>
    </Box>
  );
};

export default MediaViewer;
